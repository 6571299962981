/* Light mode */
body {
  background-color: #fff;
  color: #000;
}

/* Dark mode */
body.dark {
  background-color: #444;
  color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.table-container {
  grid-row: 1;
  min-height: 250px;
  max-height: 330px;
  overflow-y: scroll;
}
.display {
  display: grid;
}
.hide {
  display: none;
}

.table-header {
  flex-direction: row;
  background-color: #eee;
  font-weight: 600;
}

.table-footer {
  flex-direction: row;
  background-color: #ddd;
  font-weight: 600;
}

.table-row {
  display: flex;
  flex-direction: row;
  
}

.table-primary {
  background-color: #E1F4FF;
}

.table-cell {
  flex: 1;
  padding: 0px;
}

.chart {
  display: grid;
  grid-row: 2;
  height: 480px;
}

.navigationButton, .date {
  display: inline-block;
  /* float: left; */
  padding-right: 10px;
  cursor: pointer;
}

.navigation::after {
  content: "";
  clear: both;
  display: table;
}

.twenty-px-margin-top {
  margin-top: 20px;
}

.option-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard .title {
  font-weight: 600;
}

.energy-line-graph {
  height: 400px; 
  max-width: 100%;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .energy-line-graph {
    height: 260px;
    margin-top: 0px;
  }

  .row-two-graph {
    height: 220px;
    margin-top: 0px;
  }
}

.responsive-energy-table {
  margin-bottom: 10px;
}

.small-text {
  font-size: 0.8rem;
}

.calendar-navigation {
  display: block;
  margin-top: 5px;
}

.calendar-navigation .current-month {
  font-size: 1rem;
  font-weight: 800;
}